import React, { useState, useEffect, Component } from 'react';
import './App.css';
import firebase from './firebase'
import { GuestInput } from './GuestInput'
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import QRCode from 'qrcode.react';
import { Dialog, DialogTitle, Avatar, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import { red } from '@material-ui/core/colors';


//firebase.firestore().collection('times').add({
//title:'Hey'
//})

function Acceptance(props) {
  const [guests, setGuests] = React.useState([])
  // const [invitation, setInvitation] = React.useState(selectedValue)
  const [newGuestName, setnewGuestName] = React.useState()
  const { onClose, invitation, open } = props;

  const handleClose = () => {
    onClose(invitation);
  };

/*   const handleListItemClick = value => {
    onClose(value);
  }; */

  React.useEffect(() => {
    //const fetchData = async () => {
    //let params = queryString.parse(location.search);
    console.log(props);
    const db = firebase.firestore()
    return db.collection('guests').where("invitation_id", "==", invitation)
      .onSnapshot((snapshot) => {
        const guestsData = []
        snapshot.forEach(doc => guestsData.push(({ ...doc.data(), id: doc.id })))
        setGuests(guestsData);
        console.log(guestsData);
      });
    /* return db.collection('guests').where("name", "==", "Lizaldo J.").onSnapshot((snapshot) => {
      const guestsData = []
      snapshot.forEach(doc => guestsData.push(({...doc.data(), id: doc.id})))
      setGuests(guestsData);
    }); */
    /* const data = await db.collection("guests").get()
    setGuests(data.docs.map(doc => ({...doc.data(), id: doc.id}))) */
    //}
    // fetchData() //No doing async reqyests
  }, [invitation]);

  const onCreate = () => {
    const db = firebase.firestore()
    db.collection("guests").add({ name: newGuestName })
    /* db.collection('guests').doc('0').set(
        { guests: [{ name: newGuestName, when: new Date() }] },
        { merge: true }
      ) */
  }

  const onUpdateAttend = (guest, decision) => {
    const db = firebase.firestore()
    db.collection('guests').doc(guest.id).set({
      ...guest,
      attend: decision
    })
  }

  const ColorButton = withStyles(theme => ({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      borderRadius: 3,
      border: 0,
      color: 'white',
      height: 30,
      padding: '0 10px',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
      textTransform: 'capitalize',
    },
  }))(Button);


  return (
    <Dialog className="align-self-center align-items-center text-center" onClose={handleClose} open={open}>
      <DialogTitle>Seleccione los invitados que asistirán:</DialogTitle>
      <List>
        {guests.map(guest => (
          <ListItem button /* onClick={() => handleListItemClick(guest)} */ key={guest.id}>
            <ListItemText primary={guest.name} />
            <Switch
              checked={guest.attend}
              onChange={(e) => { onUpdateAttend(guest, e.target.checked); }}
              value="checkedB"
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </ListItem>
        ))}
      </List>
      <Link to={`/rsvp/${invitation}`}
        style={{ textDecoration: 'none', align: 'center' }}
        activeStyle={{color: 'white'}}
        className="Search-invitation-button">RSVP</Link>
      {/* <Button>Continuar</Button>  */}
    </Dialog>
  );
}

Acceptance.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  invitation: PropTypes.string.isRequired,
};

export default Acceptance;

