import React from 'react'
import firebase from './firebase'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Input from '@material-ui/core/Input'
import QRCode from 'qrcode.react';
import { MDCTextField, VALIDATION_ATTR_WHITELIST } from '@material/textfield';
import { PDFDownloadLink, Document, Page, Canvas, View } from '@react-pdf/renderer';
import "./GuestInput.css"
import { render } from '@testing-library/react'
import ReactDOM from 'react-dom';
import jsPDF from 'jspdf';
import canvg from 'canvg';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Gift from './gift.png';
import Plane from './plane.png';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Dialog, DialogTitle, Typography, DialogActions, DialogContent } from '@material-ui/core';
import './App.css';

export const GuestInput = ({ guest }) => {
    const [name, setName] = React.useState(guest.name)
    const [lastname, setLastName] = React.useState(guest.lastname)
    const [fructuose, setFructuose] = React.useState(guest.fructuose)
    const [gluten, setGluten] = React.useState(guest.gluten)
    const [lactose, setLactose] = React.useState(guest.lactose)
    const [drink, setDrink] = React.useState(guest.drink)
    const [mixer, setMixer] = React.useState(guest.mixer)
    const [email, setEmail] = React.useState(guest.email)
    const [others, setOthers] = React.useState(guest.others)
    const [id, setId] = React.useState(guest.id)
    const [errorEmail, setErrorMail] = React.useState(false)
    const [emailHintText, setEmailHintText] = React.useState('')
    const [open, setOpen] = React.useState(false)

    const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

    React.useEffect(() => {
        const db = firebase.firestore()
        db.collection('guests').doc(guest.id).set({
            ...guest
            , name
            , lastname
            , fructuose
            , gluten
            , lactose
            , drink
            , mixer
            , email
            , id
            , others
        })
    }, [fructuose, name, lastname, gluten, lactose, drink, mixer, email, id, others]);

    const ColorButton = withStyles(theme => ({
        root: {
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            borderRadius: 3,
            border: 0,
            color: 'white',
            height: 30,
            padding: '0 10px',
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        },
        label: {
            textTransform: 'capitalize',
        },
    }))(Button);

    const onUpdate = () => {
        const db = firebase.firestore()
        db.collection('guests').doc(guest.id).set({
            ...guest
            , name
            , lastname
            , fructuose
            , gluten
            , lactose
            , drink
            , mixer
            , email
            , id
        })
    }

    const QRCODE = () => (
        <QRCode
            value={id}
            renderAs={'canvas'}
            size={250} />
    )

    const handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        switch (name) {
            case "Email":
                emailRegex.test(value)
                    ? setErrorMail(false)
                    : setErrorMail(true);
                break;
            default:
                break;
        }
        errorEmail ? setEmailHintText('Email no válido') : setEmailHintText('');
        setEmail(value);

    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };


    const jsPdfGenerator = () => {

        // alert(id)

        var canvas = document.getElementById(id);
        /*const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");*/
        /*  let downloadLink = document.createElement("a");
         downloadLink.href = pngUrl;
         downloadLink.download = "yeah.png";
         document.body.appendChild(downloadLink);
         downloadLink.click();
         document.body.removeChild(downloadLink);
        */

        //var doc = new jsPDF('p', 'pt');

        var doc = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: [200,280]});

        doc.setFillColor(255,255,255).rect(0, 0, 200, 240, 'F')
        doc.setFillColor(10,141,147).rect(0, 0, 200, 70, 'F')

        //doc.setTextColor(255,255,255).text(50, 25, name + ' ' + lastname)
        doc.setTextColor(255,255,255).setFontSize(14).text(58, 20, name + ' ' + lastname)

        doc.setFontSize(8).setTextColor(255,255,255).text(15, 45, 'DESTINO')
        doc.text(86, 45, 'FECHA')
        doc.text(158, 45, 'HORA')

        doc.setFontSize(14).text(15, 62, 'SDQ')
        doc.text(70, 62, '02 MAYO')
        doc.text(152, 62, '6 PM')

        doc.setTextColor(125,105,63).setFontSize(18).text(30, 95, 'LUZ')
        doc.addImage(Plane, 'PNG', 80, 82, 16, 16);
        doc.text(110, 95, 'LIZALDO')
      

        doc.setTextColor(10,141,147).setTextColor(0,0,0).setFontSize(8).text(10, 120, 'CEREMONIA')
        // doc.text(110, 140, 'CELEBRACIÓN')

        doc.setTextColor(125,105,63).setFontSize(8).text(20, 140, 'IGLESIA Y CONVENTO DE LOS DOMINICOS')
        doc.setTextColor(0,0,0).setFontSize(6).text(60, 150, 'CALLE PADRE BILLINI 10210.')


        doc.setFontSize(8).text(10, 170, 'CELEBRACIÓN') 
        doc.setTextColor(125,105,63).text(46, 190, 'EMBASSY SUITES BY HILTON')
        doc.setTextColor(0,0,0).setFontSize(6).text(72,200, 'AV. TIRADENTES 32')

        // doc.setFontSize(6).text(20,200, 'AV. TIRADENTES 32, SANTO DOMINGO 10119, REP. DOM.')






        // var c = new canvg('canvas', QRCODE);

        // var canvas = document.getElementById("canvas");
        // var img = canvas.toDataURL("image/png");

        // doc.addSVG(svgAsText, 20, 20, doc.internal.pageSize.width - 20 * 2)

        // doc.canvas.childNodes = {};
        /*doc.context2d.canvas = doc.canvas;
        doc.context2d.font = undefined; 

        // use the canvg render the SVG onto the 
        // PDF via the jsPDF canvas plugin.*/

        var pngforpdf = canvas.toDataURL("image/png")

        // alert(pngforpdf)

        doc.addImage(pngforpdf, 'PNG', 70, 210, 60, 60);

        doc.save(guest.name + guest.lastname + ".pdf")


    }

    const searchInvitation = makeStyles(theme => ({
        root: {
            padding: '2px 0px',
            display: 'flex',
            alignItems: 'center',
            minWidth: 100,
            width: '100%',
        },
        input: {
            fontSize: 14,
            minWidth: 200, 
          maxWidth: 300,
          width: '70vw',
          position: 'relative',
        },
        iconButton: {
            padding: 5,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }));

    const CssTextField = withStyles({
         root: {
          /*'& label.Mui-focused': {
            color: 'black',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'black',
            },
            '&:hover fieldset': {
              borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          }, */
          minWidth: 200, 
          maxWidth: 300,
          width: '70vw',
          position: 'relative',
        },
        /* input: {
            borderRadius: 4,
            position: 'relative',
            border: '1px solid #ced4da',
            fontSize: 16,
            color: 'white',
            width: 'auto',
            padding: '10px 12px',
            // Use the system font instead of the default Roboto font.
            fontFamily: [
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ]
          }, */
      })(TextField);

    const WeddingPass = () => (
        <Document>
            <Page>
            </Page>
        </Document>
    )



    /*   const onDelete = () => {
          const db = firebase.firestore()
          db.collection('guests').doc(guest.id).delete()
      } */

    const cssSearchInvitation = searchInvitation();

    return (
        <div>
            <div>
                <div>
                    <div class="Textfields-guests">
                        <CssTextField 
                            variant='outlined'
                            value={name}
                            label='Nombre'
                            disabled='true'
                            size='small'
                            /* fullWidth */>
                            {/* <input value={name} class="mdc-text-field__input" onChange={(e) => { setName(e.target.value); }} /> */}
                        </CssTextField>
                    </div>
                    <div class="Textfields-guests">
                        <CssTextField
                            variant='outlined'
                            value={lastname}
                            label='Apellido'
                            disabled='true'
                            size='small'>
                            {/* <input value={lastname} onChange={(e) => { setLastName(e.target.value); }} /> */}
                        </CssTextField>
                    </div>
                </div>
                <div class="Textfields-guests">
                    <TextField
                        className={cssSearchInvitation.input}
                        id='Email'
                        variant='outlined'
                        name='Email'
                        value={email}
                        label='Email'
                        type='email'
                        size='small'
                        /* onError={(e) => { setErrorMail(e.target.value); }} */
                        error={errorEmail}
                        helperText={emailHintText}
                        /*  onChange={handleChange} > */
                        onChange={(e) => { setEmail(e.target.value); }}  >
                        {/* <input value={email} class="mdc-text-field__input" onChange={(e) => { setEmail(e.target.value); }} /> */}
                    </TextField>
                </div>
                <div>
                    <label>Alérgico/a a:</label>
                    <div class="flex-column">
                        <div>
                            Fructuosa
                            <Switch
                                checked={fructuose}
                                onChange={(e) => { setFructuose(e.target.checked); }}
                                value={fructuose}
                                color="primary"
                                size="small"
                                inputProps={{ 'Arial': 'primary checkbox' }}
                            />
                        </div>
                        <div>
                            Gluten
                            <Switch
                                checked={gluten}
                                onChange={(e) => { setGluten(e.target.checked); }}
                                value={gluten}
                                color="primary"
                                size='small'
                                inputProps={{ 'Arial': 'primary checkbox' }}
                            />
                        </div>
                        <div>
                            Lactosa
                            <Switch
                                checked={lactose}
                                onChange={(e) => { setLactose(e.target.checked); }}
                                value={lactose}
                                color="primary"
                                size='small'
                                inputProps={{ 'Arial': 'primary checkbox' }}
                            />
                        </div>
                        <div>
                            <TextField
                                id='others'
                                className={cssSearchInvitation.input}
                                variant='outlined'
                                name='others'
                                value={others}
                                label='Otros Alérgenos'
                                type='text'
                                size='small'
                                onChange={(e) => { setOthers(e.target.value); }} >
                                {/* <input value={email} class="mdc-text-field__input" onChange={(e) => { setEmail(e.target.value); }} /> */}
                            </TextField>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <label>Bebida alcohólica preferida:</label>
                </div>
                <div>
                    <select defaultValue="Seleccione" value={drink} onChange={(e) => { setDrink(e.target.value); }}>
                        <option> Seleccione  </option>
                        <option> No Tomo Alcohol  </option>
                        <option> Vino </option>
                        <option> Champaña </option>
                        <option> Cava </option>
                        <option> Tequila </option>
                        <option> Sidra </option>
                        <option> Vodka </option>
                        <option> Whisky </option>
                        <option> Tequila </option>
                        <option> Ron </option>
                        <option> Ginebra </option>
                        <option> Brandy </option>
                        <option> Coñac </option>
                    </select>
                </div>
            </div>
            <div>
                <div>
                    <label>Para mezclar:</label>
                </div>
                <div>
                    <select defaultValue="Seleccione" value={mixer} onChange={(e) => { setMixer(e.target.value); }}>
                        <option> Seleccione  </option>
                        <option> Me lo tomo puro  </option>
                        <option> Coca Cola </option>
                        <option> Seven Up </option>
                        <option> Agua tónica </option>
                        <option> Soda Amarga </option>
                    </select>
                </div>
            </div>
            <QRCode
                id={id}
                value={id}
                renderAs={'canvas'}
                size={250}
                className="Qrcode-hide" />
            <div>

                <div className="row"> 
                    <IconButton type="submit" className={cssSearchInvitation.iconButton} Arial="search">
                        <ColorButton variant="outlined" color="primary" onClick={jsPdfGenerator}>WeddingPass</ColorButton>
                    </IconButton>
                    <IconButton color="primary" className={cssSearchInvitation.iconButton} onClick={handleClickOpen} Arial="directions">
                        {/* <GiftIcon /> */}
                        <input type='image' className="gift" src={Gift}></input>
                    </IconButton>
                    {/* <Button>Regalar</Button>
                <Button onClick={jsPdfGenerator}>WeddingPass</Button> */}
                </div>
            </div>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Regalos
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                    Nuestro mejor regalo es que estés con 
                    nosotros en nuestro día, pero si deseas
                    hacernos un obsequio,
                    ¡Aquí están nuestros números de cuenta! 
                    </Typography>
                    <Typography gutterBottom>
                        775077001 (Banco Popular)
                    </Typography>
                    <Typography gutterBottom>
                        ES21 2100 3167 0121 0100 4875 (La Caixa)
                    </Typography>
                    </DialogContent>
            </Dialog>
            {/* <PDFDownloadLink document={<WeddingPass />} fileName="somename.pdf">
                <Button>Descarga tu WeddingPass</Button> */}

            {/* {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Descarga tu WeddingPass')} */}
            {/* </PDFDownloadLink>
            <QRCODE></QRCODE> */}
            {/* <div>
                <Button outlined class="btn" onClick={onUpdate}>Update</Button>
                <Button Outlined class="btn" onClick={onDelete}>Delete</Button>
            </div>  */}
        </div>
    )
}