import React, { useState, useEffect, Component } from 'react';
import './App.css';
import firebase from './firebase'
import { GuestInput } from './GuestInput'
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import QRCode from 'qrcode.react';
import { Dialog, DialogTitle, Avatar, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import { red } from '@material-ui/core/colors';


//firebase.firestore().collection('times').add({
//title:'Hey'
//})

function GiftDialog(props) {
  const [guests, setGuests] = React.useState([])
  // const [invitation, setInvitation] = React.useState(selectedValue)
  const [newGuestName, setnewGuestName] = React.useState()
  const { onClose, open } = props;

  React.useEffect(() => {
  }, []);

  const handleGift = () => {
    onClose(open);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  return (
    <Dialog className="align-self-center align-items-center text-center" open={open}>
      <DialogTitle>Cuando decidas hacernos un regalo están son nuestras cuentas de banco:</DialogTitle>
      <tc>
        <text>Banco Popular</text>
        <text>775077001</text>
      </tc>
      <tc>
        <text>La Caixa</text>
        <text>775077001</text>
      </tc>
    </Dialog>
  );
}

GiftDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default GiftDialog;

