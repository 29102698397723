import React, { useState, component, useEffect } from 'react';
import './App.css';
import firebase from './firebase'
import { GuestInput } from './GuestInput'
import queryString from 'query-string';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import { rgbToHex } from '@material-ui/core';
import Acceptance from './Acceptance';
import GiftDialog from './GiftDialog';
import PropTypes from 'prop-types';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { green, purple, red } from '@material-ui/core/colors';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import GiftIcon from '@material-ui/icons/CardGiftcardTwoTone';
import DirectionsIcon from '@material-ui/icons/Directions';
import Gifts from './Gifts';
import Gift from './gift.png';
import Background from './P1140289.JPG';
import { Dialog, DialogTitle, Typography, DialogActions, DialogContent } from '@material-ui/core';


function Invitation () {

    const [invitation, setInvitation] = useState('');
    const [open, setOpen] = useState(false);
    const [gift, setGift] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickGift = () => {
        setGift(true);
    };

    const handleClose = value => {
        setOpen(false);
    };

    const handleGift = event => {
        setGift(event.target.value);
    };

    const handleChangeInvitation = event => {
        setInvitation(event.target.value);
    };

    /*  const ColorButton = withStyles(theme => ({
         root: {
           color: theme.palette.getContrastText(purple[500]),
           backgroundColor: red[500],
           '&:hover': {
             backgroundColor: red[700],
           },
         },
       }))(Button); */

    const ColorButton = withStyles(theme => ({
        root: {
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            borderRadius: 3,
            border: 0,
            color: 'white',
            height: 30,
            padding: '0 10px',
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            margin: '7px',
        },
        label: {
            textTransform: 'capitalize',
        },
    }))(Button);

    const searchInvitation = makeStyles(theme => ({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            minWidth: 280,
            width: '96%',
            marginBottom: '10px',
            position: 'relative'
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
            fontSize: 14,
            fontFamily: 'Arial',
        },
        iconButton: {
            padding: 10,
            marginLeft: -10,
        },
        divider: {
            height: 28,
            margin: 10,
        },
    }));

    const cssSearchInvitation = searchInvitation();


    return (
        <div className="whole d-flex flex-column justify-content-center my-flex-container">
            <div
                className="Title-intro">
                ¡POR FIN NOS CASAMOS!
            </div>
            <div
                className="Title-location">
                Iglesia y Convento de los Dominicos | 02 Mayo, 2020, 6:00 PM
            </div>
            <Acceptance invitation={invitation} open={open} onClose={handleClose} /> {/*Hacer que no haya que dale al boton*/}
            <Dialog aria-labelledby="customized-dialog-title" onClose={handleGift} open={gift}>
                <DialogTitle id="customized-dialog-title">
                    Regalos
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Cuando decidas hacernos un regalo están son nuestras cuentas de banco:
                    </Typography>
                    <Typography gutterBottom>
                       Banco Popular:  775077001
                    </Typography>
                    <Typography gutterBottom>
                        La Caixa: 
                    </Typography>
                    </DialogContent>
            </Dialog>
            {/* <Link to={`/acceptance/${invitation}`} className="Search-invitation-button">RSVP</Link> */}
            <Paper component="form" className={cssSearchInvitation.root}>
                <InputBase
                    className={cssSearchInvitation.input}
                    value={invitation}
                    onChange={handleChangeInvitation}
                    placeholder="Invitación"
                    inputProps={{ 'roboto': 'Código de Invitación' }}
                />
                <ColorButton variant="outlined" color="primary" onClick={handleClickOpen}>RSVP</ColorButton>
                {/* <Divider className={cssSearchInvitation.divider} orientation="vertical" /> */}
                {/* <IconButton color="primary" className={cssSearchInvitation.iconButton} onClick={handleClickGift} roboto="directions"> */}
                    {/* <GiftIcon /> */}
                    {/* <input type='image' className="gift" src={Gift}></input> */}
                {/* </IconButton> */}
            </Paper>
        </div>
    );
}

export default Invitation;

