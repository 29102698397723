import React, { useState, useEffect, Component } from 'react';
import barcode from './barcode.gif';
import invitation from './invitation.jpg'
import qrcode from './lizaldojimenez_QR.svg';
import leftColumn from './left_column.svg';
import './App.css';
import firebase from './firebase'
import { GuestInput } from './GuestInput'
import queryString from 'query-string';
import Nav from './Nav';
import Rsvp from './Rsvp';
import Gifts from './Gifts';
import BoardingPass from './BoardingPass';
import Invitation from './Invitation';
import Acceptance from './Acceptance';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


import QRCode from 'qrcode.react';

function App() {
  return (
    <Router>
      <div className="container">
        {/* <Nav /> */}
        <Switch>
          <Route path="/" exact component={Invitation} />
          <Route path="/gifts" exact component={Gifts} />
          <Route path="/BoardingPass" component={BoardingPass} />
          <Route path="/acceptance/:id" component={Acceptance}/>
          <Route path="/rsvp/:id" component={Rsvp}/>
        </Switch>
      </div>
    </Router>
  );
}

const Home = () => (
  <div>
    <h1>Home Page</h1>
  </div>
);

export default App;

