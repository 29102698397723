import React, { useState, useEffect, Component } from 'react';
import './App.css';
import firebase from './firebase'
import {GuestInput} from './GuestInput'
import queryString from 'query-string';

import QRCode from 'qrcode.react';

function BoardingPass({}) {
    return (
        <div>
            <h1>Boarding Pass Page</h1>
            <QRCode
              value={'brYCPdp8NNlKpv6eP64Q'}
              renderAs={'svg'}
              size={250} />
        </div>
    );
}
  
export default BoardingPass;

