import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAy9WlIblYSV6-OlyoIZW5HkOYVyU_C9mg",
    authDomain: "luzylizaldo.firebaseapp.com",
    databaseURL: "https://luzylizaldo.firebaseio.com",
    projectId: "luzylizaldo",
    storageBucket: "luzylizaldo.appspot.com",
    messagingSenderId: "420613082199",
    appId: "1:420613082199:web:ec2d89d1fa8a7a20062712",
    measurementId: "G-DFRHNVF4DR"
  };

  firebase.initializeApp(firebaseConfig);

  export default firebase