import React, { useState, useEffect, Component } from 'react';
import './App.css';
import firebase from './firebase';
import { GuestInput } from './GuestInput';
import Pagination from './Pagination';

import QRCode from 'qrcode.react';
import Acceptance from './Acceptance';

//firebase.firestore().collection('times').add({
//title:'Hey'
//})

function Rsvp({ match }) {
  const [guests, setGuests] = React.useState([]);
  const [newGuestName, setnewGuestName] = React.useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [guestsPerPage] = useState(1);

  React.useEffect(() => {
    //const fetchData = async () => {
    //let params = queryString.parse(location.search);
    const db = firebase.firestore()
    return db.collection('guests').where("invitation_id", "==", match.params.id)
      .where("attend", "==", true)
      .onSnapshot((snapshot) => {
        const guestsData = []
        snapshot.forEach(doc => guestsData.push(({ ...doc.data(), id: doc.id })))
        setGuests(guestsData);
        console.log(match);
      });
    /* return db.collection('guests').where("name", "==", "Lizaldo J.").onSnapshot((snapshot) => {
      const guestsData = []
      snapshot.forEach(doc => guestsData.push(({...doc.data(), id: doc.id})))
      setGuests(guestsData);
    }); */
    /* const data = await db.collection("guests").get()
    setGuests(data.docs.map(doc => ({...doc.data(), id: doc.id}))) */
    //}
    // fetchData() //No doing async reqyests
  }, []);

  const onCreate = () => {
    const db = firebase.firestore()
    db.collection("guests").add({ name: newGuestName })
    /* db.collection('guests').doc('0').set(
        { guests: [{ name: newGuestName, when: new Date() }] },
        { merge: true }
      ) */
  }

  // Get Current Guest
  const indexOfLastGuest = currentPage * guestsPerPage;
  const indexOfFirstGuest = indexOfLastGuest - guestsPerPage;
  const currentGuests = guests.slice(indexOfFirstGuest, indexOfLastGuest);

  // Change page 
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className="whole-invite position-relative align-middle text-center d-flex mx-auto h-100 w-60 align-self-center align-items-center flex-column justify-content-center">
      {/* <div class="flex-column">
        <input value={newGuestName} onChange={(e) => setnewGuestName(e.target.value)}/>
        <button onClick={onCreate}>Create</button>
      </div> */}
      <div className="Title-rsvp flex-column">Invitados</div>
      <div>
        {currentGuests.map(guest => (
          <li key={guest.id} className="Guest-lists">
            <GuestInput guest={guest} />
          </li>
        )
        )}
        {/* <QRCode
              value={'brYCPdp8NNlKpv6eP64Q'}
              renderAs={'svg'}
              size={250} /> */}
      </div>
      <div className="flex-column">
        <Pagination guestsPerPage={guestsPerPage} totalGuests={guests.length} paginate={paginate} />
      </div>
    </div>
  );
}

export default Rsvp;

