import React, { useState, useEffect, Component } from 'react';
import './App.css';
import {Link} from 'react-router-dom';

function  Gifts() {

    useEffect(() => {
        fetchItems();
    }, []);

    const [items, setItems] = useState([]);

    const fetchItems = async () => {
        const data = await fetch('https://fortnite-api.theapinetwork.com/store/get', {
            method: 'get',
            header: new Headers({
                'Authorization': 'authorization', 
                'Content-Type': 'application/x-www-form-urlencoded'
              }), 
        });
        
        const items = await data.json();
        console.log(items.data);
        setItems(items.data);
    };

    return (
        <div>
            {items.map(item => (
                <h1 key={item.itemId}>
                    <Link to={`/gifts/${item.itemId}`}>{item.item.name}</Link>    
                </h1>
            ))}
        </div>
    );
}
  
export default Gifts;

